
.StoreMarketingTwo{
    .MarketingTwoHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .deleBtn{
            width: 90px;
            height: 30px;
            line-height: 0;
            color: #EB6547;
            background: #ffffff;
            border-color: #EB6547;
        }
    }
    .toBtn{
        display: flex;
        justify-content: center;
        margin-top: 80px;
        .btn1{
            width: 100px;
            height: 30px;
            line-height: 0;
        }
        .btn1:nth-child(1){
            background: #ffffff;
            border-color: #1122D8;
            color: #1122D8;
        }
        .btn1:nth-child(2){
            background: #1122D8;
            border-color: #1122D8;
            color: #ffffff;
        }
    }
}
